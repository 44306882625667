import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let form = $(this.element);
    form.areYouSure();

    addEventListener("trix-change", function(event) {
      console.log("trix-change")
      form.trigger('checkform.areYouSure');
    })
  }
}
