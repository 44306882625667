import { Controller } from "@hotwired/stimulus"
import Pikaday from 'pikaday'
import 'pikaday/scss/pikaday.scss'
import moment from "moment-timezone"

export default class extends Controller {
  static targets = [
    "container", "draft", "visibleDate", "scheduler",
    "submit", "submitDraft", "submitScheduled", "triggerDismissScheduling",
    "timeZone",
  ]
  static disabledClass = "d-none"
  static dateFormat = 'D/MM/Y'

  updatingDate = false;

  connect() {
    this._createDatePicker()
  }

  update() {
    let selectedDay = this.daySelect().value
    let selectedMonth = this.monthSelect().value - 1
    let selectedYear = this.yearSelect().value
    let selectedHour = this.hourSelect().value
    let selectedMinute = this.minuteSelect().value
    let currentTime = moment.tz(new Date(), this.timeZoneTarget.value)
    let schedulerTime = moment.tz({
        year: selectedYear,
        month: selectedMonth,
        day: selectedDay,
        hour: selectedHour,
        minute: selectedMinute,
      }, this.timeZoneTarget.value)

    if ( schedulerTime.isAfter(currentTime) ) {
      this.enableScheduledMode()
    } else {
      this.enableSubmitMode()
    }
  }

  daySelect() { return document.getElementById("post_scheduled_at_3i") }
  monthSelect() { return document.getElementById("post_scheduled_at_2i") }
  yearSelect() { return document.getElementById("post_scheduled_at_1i") }
  hourSelect() { return document.getElementById("post_scheduled_at_4i") }
  minuteSelect() { return document.getElementById("post_scheduled_at_5i") }

  toggleScheduler(event) {
    this.schedulerTarget.classList.toggle("d-none")

    if ( this._isCalendarVisible() ) {
      this.setCurrentTime()
    }
  }

  setCurrentDate() {
    this.updatingDate = true;
    this.datePicker.setDate(new Date())
    this.updatingDate = false;
  }

  setCurrentTime(options = {force: false}) {
    let now = new Date() // TODO use moment() timezone

    let coeff = 1000 * 60 * 5; // round to nearest 5 minuts
    let rounded = new Date(Math.round(now.getTime() / coeff) * coeff);
    let hour = String(rounded.getHours()).padStart(2, "0")
    let minutes = String(rounded.getMinutes()).padStart(2, "0") // Always return 2 numbers, and start with 0 if only one  now.getMinutes()

    // Set time unless is already set
    if ( !this.hourSelect().value.length ) {
      this.hourSelect().value = hour
    }
    if ( !this.minuteSelect().value.length ) {
      this.minuteSelect().value = minutes
    }

    if ( options.force === true ) {
      this.hourSelect().value = hour
      this.minuteSelect().value = minutes
    }
  }

  disableSubmit() {
    this.submitTarget.classList.add(this.constructor.disabledClass)
  }

  enableScheduledMode() {
    this.disableSubmit()
    this._printVisibleDate()
    this.submitScheduledTarget.classList.remove(this.constructor.disabledClass)
    this.triggerDismissSchedulingTarget.classList.remove("d-none")
  }

  dismissScheduling(event) {
    this.setCurrentDate()
    this.setCurrentTime({force: true})
    this.enableSubmitMode()
    this.triggerDismissSchedulingTarget.classList.add("d-none")
  }

  disableSubmitScheduled() {
    this.submitScheduledTarget.classList.add(this.constructor.disabledClass)
  }

  enableSubmitMode() {
    this.disableSubmitScheduled()
    this.submitTarget.classList.remove(this.constructor.disabledClass)
    this.visibleDateTarget.innerHTML = I18n.t("manage.posts.form.immediately")
  }

  _getMonthNames() {
    let _monthNames = I18n.t("date.month_names")
    // Sucks, but Rails array of months has an empty value first ¯\_(ツ)_/¯
    _monthNames.shift()
    return _monthNames
  }

  _printVisibleDate() {
    let output = moment
      .tz(this.datePicker.getDate(), this.timeZoneTarget.value)
      .format(this.constructor.dateFormat)

    this.visibleDateTarget.innerHTML = output
  }

  _isDraft() {
    return this.draftTarget.checked
  }

  _isCalendarVisible() {
    return !this._isCalendarHidden()
  }

  _isCalendarHidden() {
    return this.schedulerTarget.offsetParent === null
  }

  _createDatePicker() {
    let self = this
    let monthNames = this._getMonthNames()

    this.datePicker = new Pikaday({
      field: document.getElementById('datepicker'),
      format: this.constructor.dateFormat,
      firstDay: 1,
      minDate: new Date(),
      bound: false,
      container: document.getElementById('calendar-container'),
      theme: "aixeta-datepicker",
      keyboardInput: false,
      i18n: {
        previousMonth : 'Previous Month',
        nextMonth     : 'Next Month',
        months        : monthNames,
        weekdays      : I18n.t("date.day_names"),
        weekdaysShort : I18n.t("date.abbr_day_names"),
      },
      onSelect: function(date) {
        if (self.updatingDate)  return;

        let selectedDate = moment(date).set({
            'hour': parseInt(document.querySelector('[name="post[scheduled_at(4i)]"]').value, 10),
            'minute': parseInt(document.querySelector('[name="post[scheduled_at(5i)]"]').value, 10),
            'second': 0,
            'millisecond': 0
        }).tz(self.timeZoneTarget.value, true);

        const now = moment.tz(new Date(), self.timeZoneTarget.value)

        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        self.daySelect().value = day
        self.monthSelect().value = month
        self.yearSelect().value = year

        if ( selectedDate.isAfter(now) ) {
          self.enableScheduledMode()
        } else {
          self.enableSubmitMode()
          self.dismissScheduling();
        }
      }
    })
  }
}
