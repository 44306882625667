import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "message" ]
  static messageStates = { read: "", unread: "inbox-message--unread" }

  markAsReadAll(event) {
    event.preventDefault()
    let self = this

    this.messageTargets.forEach(function(el){
      if ( !el.classList.contains(self.constructor.messageStates.unread) ) {
        return
      }

      el.classList.remove(self.constructor.messageStates.unread)
      $.ajax({
        url: el.dataset.messageReadUrl,
        method: "PATCH"
      })
    })
  }
}
