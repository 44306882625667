import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["busy", "errors", "paymentElement"];

  static values = {
    clientSecret: String,
    publishableKey: String,
    theme: { type: String, default: '' }
  }

  static appearance(theme) {
    // https://stripe.com/docs/elements/appearance-api
    if (theme === "omnipresent") {
      return {
        appearance: {
          theme: 'night',
          variables: {
            fontFamily: 'Sohne, system-ui, sans-serif',
            fontWeightNormal: '500',
            borderRadius: '8px',
            colorBackground: '#0A2540',
            colorPrimary: '#110a44',
            colorPrimaryText: '#1A1B25',
            colorText: 'white',
            colorTextSecondary: 'white',
            colorTextPlaceholder: '#97A1B4',
            colorIconTab: 'white',
            colorLogo: 'dark'
          },
          rules: {
            '.Input, .Block': {
              backgroundColor: '#110a4463',
              border: '1.5px solid var(--colorPrimary)'
            }
          },
        },
      }
    } else {
      return {
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: '#110a4463',
            colorText: '#333333',
            colorDanger: '#B91C1C',
          },
        },
      }
    }
  }

  connect() {
    this.stripe = window.Stripe(this.publishableKeyValue, {
      locale: "en",
    });

    this.elements = this.stripe.elements({
      ...this.constructor.appearance(this.themeValue),
      clientSecret: this.clientSecretValue,
    });

    const paymentElementOptions = {
      layout: 'tabs',
      wallets: {
        applePay: 'never',
        googlePay: 'never',
      },
      fields: {
        billingDetails: {
          address: {
            // explained to aixeta that perhaps this is not a good move,
            // they understood and decided to go with it.
            country: 'auto',
          },
        },
      },
      // explained to aixeta that perhaps this is not a good move,
      // they understood and decided to go with it.
      terms: {
        card: 'never',
      }
    };

    const paymentElement = this.elements.create('payment', paymentElementOptions);
    paymentElement.mount(this.paymentElementTarget);
  }

  async confirmSetup(event) {
    event.preventDefault();

    this.busyTarget.classList.remove('d-none');
    this.errorsTarget.textContent = "";

    const { error, setupIntent } = await this.stripe.confirmSetup({
      elements: this.elements,
      redirect: 'if_required',
    });

    if (error) {
      this.busyTarget.classList.add('d-none');

      if (error.type !== 'validation_error') {
        // The Stripe payment element already show errors on inputs
        this.errorsTarget.textContent = error.message;
      }
    } else {
      event.detail.fetchOptions.body.append('payment_method_id', setupIntent.payment_method);

      // https://turbo.hotwired.dev/handbook/drive#pausing-requests
      event.detail.resume();
    }
  }

  advanceOnSuccess(event) {
    if (event.detail.success) {
      var step = new patronageCreditCardStep;
      step.advance();
    }
  }
}
