import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "message" ]
  static messageStates = { read: "", unread: "inbox-message--unread" }

  markAsRead(event) {
    let trigger = event.target
    let message = trigger.closest("[data-inbox-target='message']")
    message.classList.remove(this.constructor.messageStates.unread)
    trigger.classList.remove("fa-envelope-open")
    trigger.classList.add("fa-envelope")

    $.ajax({
      url: this.data.get("read-url"),
      method: "PATCH"
    })
  }

  markAsUnread(event) {
    let trigger = event.target
    let message = trigger.closest("[data-inbox-target='message']")
    message.classList.add(this.constructor.messageStates.unread)
    trigger.classList.remove("fa-envelope")
    trigger.classList.add("fa-envelope-open")

    $.ajax({
      url: this.data.get("unread-url"),
      method: "PATCH"
    })
  }
}
