import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "document", "feedback", "file", "upload" ]

  upload(event) {
    this.clearError()

    if ( !this.isFilePresent() ) {
      return
    }

    let self = this

    if ( !this.isValidFormat() ) {
      this.displayError("Invalid format. Must be JPG or PNG.")
      return
    }

    if ( this.isFileTooBig() ) {
      this.displayError("File too big. Max size is 10MB")
      return
    }

    this.disableUploadButton();

    this.uploadFile()
      .done( (data, textStatus, jqXHR) => {
        this.setDocumentField(data.id)
        this.displaySuccess()
        this.enableUploadButton()
      }).fail( (jqXHR, textStatus, errorThrown) => {
        this.displayError()
        this.enableUploadButton()
      });
  }

  setDocumentField(value) {
    this.documentTarget.value = value
  }

  disableUploadButton() {
    this.uploadTarget.disabled = true
    this.uploadTarget.innerHTML = this.data.get("disableWith")
  }

  enableUploadButton() {
    this.uploadTarget.innerHTML = this.data.get("enableWith")
    this.uploadTarget.disabled = false
  }

  displaySuccess() {
    this.fileTarget.classList.remove("is-invalid")
    this.fileTarget.classList.add("is-valid")
    this.feedbackTarget.className = "valid-feedback d-block"
    this.feedbackTarget.innerHTML = `<i class="fas fa-check"></i>`
  }

  displayError(errorMessage = I18n.t("errors.generic_short")) {
    this.fileTarget.classList.remove("is-valid")
    this.fileTarget.classList.add("is-invalid")
    this.feedbackTarget.className = "invalid-feedback d-block"
    this.feedbackTarget.innerHTML = errorMessage
  }

  clearError() {
    this.fileTarget.classList.remove("is-invalid")
    this.feedbackTarget.classList.remove("invalid-feedback", "d-block")
    this.feedbackTarget.innerHTML = ""
  }

  isFilePresent() {
    return this.fileTarget.files.length;
  }

  isValidFormat() {
    return ["image/jpg","image/jpeg", "image/png"]
      .includes(this.fileTarget.files[0].type)
  }

  isFileTooBig() {
    return this.fileTarget.files[0].size > (1024 * 1024 * 10)
  }

  uploadFile() {
    let self = this
    let data = new FormData()
    data.append("identity_document", this.fileTarget.files[0])

    return $.ajax({
      url: `${App.api.root_url}stripe/files`,
      method: "POST",
      data: data,
      dataType: "json",
      processData: false,
      contentType: false,
      cache: false,
      beforeSend: function(xhr) { self.authenticateRequest(xhr) }
    })
  }

  authenticateRequest(xhr) {
    let api_key = $('meta[name="user"]').attr('content')
    let csrf_token = $('meta[name="csrf-token"]').attr('content')

    xhr.setRequestHeader('X-CSRF-Token', csrf_token)
    xhr.setRequestHeader("Authorization", `Token token=${api_key}`)
  }
}
