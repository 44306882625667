import { Controller } from "@hotwired/stimulus"
import Link from "../models/link"
import LinkForm from "../views/links"

export default class extends Controller {
  static targets = [ "add" ]

  new() {
    let link_form = new LinkForm();

    return link_form.render();
  }

  edit(event) {
    let data = JSON.parse(event.currentTarget.dataset.aigua);
    let id = data.id;
    let link = Link.find(id);
    let link_form = new LinkForm(link);

    return link_form.render();
  }

  delete(event) {
    let data = JSON.parse(event.currentTarget.dataset.aigua);
    let id = data.id;
    let link = Link.find(id);
    let $target = $(event.currentTarget.dataset.target);

    $target.fadeOut();
    $(".overlay").remove();

    link.delete()
      .fail( (jqXHR, textStatus, errorThrown) => {
        $target.fadeIn();
      });
  }
}
