import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "arrow", "filters" ]

  toggleFilters() {
    event.preventDefault()
    $(this.filtersTarget).slideToggle()
    this.arrowTarget.classList.toggle("fa-arrow-up")
    this.arrowTarget.classList.toggle("fa-arrow-down")
  }

  resetFilters() {
    event.preventDefault();

    $("#query_date").val("any")
    $("#query_locale").val("any")
    $("#query_search").val("")
  }
}
