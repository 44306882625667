import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "accountType", "companyDetails", "companyOnly",
    "primaryAddress", "primaryOwner", "uniqueOwner", "percentOwnership",
    "person", "additionalOwners",
  ]

  connect() {
    this.form = this.element.querySelector("#stripe-account-form")
    this._validateInputs();
    this._attachEvents();
  }

  createPerson(event) {
    event.preventDefault()

    let self = this
    let url = event.currentTarget.dataset.url

    $.ajax({
      url: url,
      method: "POST",
      dataType: "json",
      contentType: false,
      processData: false,
      beforeSend: function(xhr) {
        xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`);
      },
      success: function(json) {
        var source   = document.getElementById("stripe-account-person-template").innerHTML;
        var template = Handlebars.compile(source);
        var context = json.data.attributes;
        context.id = json.data.id;
        var html    = template(context);
        $(self.additionalOwnersTarget).prepend(html);
      },
      error: function(jqXHR, textStatus, errorThrown) {
      }
    });
  }

  deletePerson(event) {
    event.preventDefault()

    let url = `${event.currentTarget.dataset.baseUrl}/${event.currentTarget.dataset.id}`
    let personElement = event.currentTarget.closest(".additional-owner")
    let $target = $(personElement)

    $target.fadeOut()

    if ( url != null ) {
      $.ajax({
        url: url,
        method: "DELETE",
        dataType: "json",
        contentType: false,
        processData: false,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`);
        },
        success: function(data) {
          personElement.remove()
        },
        error: function(jqXHR, textStatus, errorThrown) {
          $target.fadeIn();
        }
      })
    }
  }

  processUniqueOwner(event) {
    let currentPerson = event.currentTarget
      .closest("[data-stripe-accounts-target~='person']")
    let currentPercent = currentPerson
      .querySelector("[data-stripe-accounts-target~='percentOwnership']")
    let currentPercentContainer = currentPercent
      .closest("[data-stripe-accounts-target~='percentOwnershipContainer']")


    if ( event.currentTarget.checked ) {
      currentPercent.value = 100
    } else {
      currentPercent.value = 0
    }

    //currentPercentContainer.classList.toggle("invisible", event.currentTarget.checked)
  }

  changeAccountType() {
    if ( this.changedToCompany() ) {
      this.showCompanyFields()
    } else if ( this.changedToIndividual() ) {
      this.hideCompanyFields()
    }
  }

  changedToCompany() {
    return this.accountTypeTarget.value == "company"
  }

  changedToIndividual() {
    return this.accountTypeTarget.value == "individual"
  }

  showCompanyFields() {
    this.companyOnlyTargets.forEach(function(el){
      el.classList.remove("d-none")
    })
  }

  hideCompanyFields() {
    this.companyOnlyTargets.forEach(function(el){
      el.classList.add("d-none")
    })
  }

  _attachEvents() {
    let self = this;

    // Validate each input on change
    $("#stripe-account-form .form-control").on("keyup change", function(){
      self._validateInput($(this));
    });
    $("#stripe-account-form .form-check-input").on("change", function(){
      self._validateInput($(this));
    });

    // On every form change, validate the whole thing
    $("#stripe-account-form").on("change", function() {
      self._validateInputs();
    });
  }

  _condition($input) {
    let condition;

    switch( $input.attr("type") ) {
      case "checkbox":
        condition = $input.is(":checked");
        break;
      default:
        condition = $input.val().length;
        break;
    }

    return condition;
  }

  _validateInputs() {
    let self = this;
    let fieldsToValidate = [
      "#stripe-account-form .form-control",
      "#stripe-account-form .form-check-input",
    ]

    $(fieldsToValidate.join(", ")).each(function(){
      self._validateInput($(this));
    });
  }

  _validateInput($input) {
    if ( $input.hasClass("no-validate") ) { return }

    $input.toggleClass("is-invalid", !this._condition($input));
  }
}
