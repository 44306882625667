import React, { useState } from 'react';
import ReactSelect from 'react-select';

const styles = {
  control: (styles, {isFocused}) => ({
    ...styles,
    boxShadow: isFocused ? '0 0 0 .2rem rgba(17,10,68,.25)' : 'none',
  }),
  indicatorSeparator: () => {}, // removes the "stick"
  menu: (styles) => ({
    ...styles,
    zIndex: 10,
  }),
}

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: 'rgba(17,10,68,.25)',
    primary: 'rgba(17,10,68,.60)',
  },
})

const SelectInput = (props) => {
  const { value, options, name, placeholder = "" } = props;

  return (
    <ReactSelect
      className="react-select-container"
      classNamePrefix="react-select"
      defaultValue={value}
      noOptionsMessage={({inputValue}) => `${window.I18n.t("search.filters.results_count", {count: 0})}`}
      isClearable={false}
      name={name}
      options={options}
      placeholder={placeholder}
      styles={styles}
      theme={theme}
    />
  );
}

export default SelectInput;
