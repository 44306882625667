import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output", "source" ]

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  copy(event) {
    event.preventDefault()
    this.sourceTarget.select()
    document.execCommand("copy")

    document
      .querySelector(".tooltip.show .tooltip-inner")
      .innerHTML = '<i class="far fa-thumbs-up"></i>'

    $(this.outputTarget).tooltip('update')

    setTimeout(function(){
      $(this.outputTarget).tooltip("hide")
    }, 2000);
  }
}
