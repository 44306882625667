import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "codeContainer", "codeInput" ]

  printCode(event) {
    let bannerCode = event.currentTarget.dataset.code
    this.codeContainerTarget.innerText = bannerCode
    this.codeInputTarget.value = bannerCode
  }
}
