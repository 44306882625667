import axios from "axios";
import * as tus from "tus-js-client";

export default class Video {
  constructor(file, params = { title: "video" }) {
    this.accessToken = "959e5d4b1ea10ae7c186c7d7ebe18fae";

    this.file = file;
    this.title = params.title;
  }

  upload() {
    let self = this;

    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://api.vimeo.com/me/videos",
          {
            upload: {
              approach: "tus",
              size: self.file.size,
            },
            // https://developer.vimeo.com/api/reference/videos#edit_video
            name: this.title,
            privacy: {
              download: false,
              embed: "public",
              view: "unlisted",
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        )
        .then((res) => {
          $(".progress-vimeo").removeClass("invisible").addClass("visible");

          var upload = new tus.Upload(this.file, {
            uploadUrl: res.data.upload.upload_link,
            onError: reject,
            onProgress: this.progressCallback.bind(this),
            onSuccess: () => {
              this.url = res.data.link;
              resolve(self);
            }
          });

          upload.start();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  progressCallback(bytesUploaded, bytesTotal) {
    let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed();

    $(".progress-vimeo .progress-bar")
      .css({ width: `${percentage}%` })
      .text(`${percentage}%`);
  }
}
