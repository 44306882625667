import { Controller } from "@hotwired/stimulus"
import Video from "../models/video"

export default class extends Controller {
  static targets = [ "error", "modal", "progressBar", "uploadBtn" ]

  connect() {
    this.setEventListeners()
  }

  create() {
    this.disableUpload()

    let self = this
    let file = document.getElementById("video").files[0];
    let title = document.getElementById("video-title").value
    let video = new Video(file, { title: title })
    let url = this.data.get("shop-url")

    video.upload()
      .then( (vid) => {
        $("#shop_video_url").val(vid.url);

        let data = {
          shop: { video_url: vid.url }
        }

        $.ajax({
          url: url,
          method: "PATCH",
          beforeSend: function(xhr) {
            xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`);
          },
          data: data,
          dataType: "json",
        }).done( ( data, textStatus, jqXHR ) => {
          self.resetModal()
          self.attachVideo(vid.url)
        }).fail(  ( jqXHR, textStatus, errorThrown ) => {
          let json = jqXHR.responseJSON;

          if ( !_.isEmpty(json) && _.isArray(json.errors) ) {
            var message = json.errors[0].detail;
          } else {
            var message = I18n.t("errors.generic");
          }

          let error = new ApplicationError(message);
          error.render();
        });
      }, (error) => {
        this.errorTarget.innerHTML = I18n.t("errors.generic_short")
        this.resetProgress()
      });
  }

  destroy() {
    let self = this

    $.ajax({
      url: this.data.get("shop-url"),
      method: "PATCH",
      beforeSend: function(xhr) {
        xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`);
      },
      data: { shop: { video_url: "" } },
      dataType: "json",
    }).done( ( data, textStatus, jqXHR ) => {
      self.resetModal()
      $("#shop-video-play").hide()
    }).fail(  ( jqXHR, textStatus, errorThrown ) => {
      let json = jqXHR.responseJSON;

      if ( !_.isEmpty(json) && _.isArray(json.errors) ) {
        var message = json.errors[0].detail;
      } else {
        var message = I18n.t("errors.generic");
      }

      let error = new ApplicationError(message);
      error.render();
    });
  }

  setEventListeners() {
    let self = this

    $(document).on("change", "#video", function(event) {
      if ( document.getElementById("video").files != null && document.getElementById("video").files.length > 0 ) {
        let file = document.getElementById("video").files[0];
        $(".custom-file-label").text(file.name);
      } else {
        $(".custom-file-label").text($(".custom-file-label").data("default"));
      }
    });

    $(document).on("change keyup", "#video, #video-title", function(event) {
      let filePresent = document.getElementById("video").files.length > 0;
      let titlePresent = document.getElementById("video-title").value.length > 0;

      if ( filePresent && titlePresent ) {
        self.enableUpload()
      } else {
        self.disableUpload()
      }
    });
  }

  resetModal() {
    $(".progress-vimeo .progress-bar")
      .css({width: 0})
      .text("");
    this.resetProgress()
    $("#video").val("");
    $("#video-title").val("");
    $(".custom-file-label").text($(".custom-file-label").data("default"));
    this.disableUpload()
    $(this.modalTarget).modal('hide');
  }

  attachVideo(url) {
    let playBtn = `
      <button class="show-video-button" data-behaviour="fade-in" data-target="#intro-video">
        <i class="play-icon"></i>
      </button>
    `

    if ( !$(".shop-actions .show-video-button").length ) {
      $(".shop-actions").append(playBtn);
    }

    $("#shop-video").replaceWith('<div id="shop-video" class="video"></div>')

    try {
      let playerOptions = {
        url: url,
        maxwidth: 1200,
        byline: false, // Whether to display the video owner's name.
        portrait: false, // Whether to display the video owner's portrait.
      }

      new Vimeo.Player('shop-video', playerOptions)
      resolve();
    } catch(error) {
      $("#player-error").html(I18n.t("shops.video.show.error"))
    }
  }

  resetProgress() {
    this.progressBarTarget.classList.remove("visible")
    this.progressBarTarget.classList.add("invisible")
  }

  disableUpload() {
    this.uploadBtnTarget.disabled = true
  }

  enableUpload() {
    this.uploadBtnTarget.disabled = false
  }
}
