import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "error" ]

  connect() {
    if ( this.data.get("url") ) {
      this.show()
    }
  }

  show() {
    let self = this

    this.checkIfVideoIsAvailable().then(
      (data, textStatus, jqXHR) => {
        self.appendVideo()
      },
      (jqXHR, textStatus, errorThrown) => {
        self.showError(jqXHR)
      }
    );
  }

  checkIfVideoIsAvailable() {
    return $.ajax({
        type:'GET',
        url: `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(this.data.get("url"))}`,
        dataType: 'json',
    })
  }

  appendVideo() {
    let videoPlayer = new Vimeo.Player(
      this.containerTarget.id,
      {
        url: this.data.get("url"),
        maxwidth: 1200,
        byline: false, // Whether to display the video owner's name.
        portrait: false, // Whether to display the video owner's portrait.
      }
    );
  }

  showError(response) {
    if ( response.status == 404 ) {
      this.errorTarget.innerHTML = I18n.t("shops.video.show.error")
    }
  }
}
