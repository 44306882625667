import { Controller } from "@hotwired/stimulus"
import Video from "../models/video"

export default class extends Controller {
  create() {
    $("#upload-video").attr("disabled", true);
    $("#upload-help").toggleClass("invisible");

    let self = this
    let file = document.getElementById("video").files[0];
    let title = document.getElementById("video-title").value
    let video = new Video(file, { title: title })

    video.upload()
      .then( (video) => {
        self.createItem(video)
      }, (error) => {
        self.showError()
      })
  }

  resetModal() {
    $("#upload-video-modal").modal('hide')
    $(".progress-vimeo .progress-bar")
      .css({width: 0})
      .text("")
    $(".progress-vimeo")
      .removeClass("visible")
      .addClass("invisible")
    $("#video").val("")
    $("#video-title").val("")
    $(".custom-file-label").text($(".custom-file-label").data("default"))
    $("#upload-video").attr("disabled", true)
    $("#upload-help").toggleClass("invisible")
  }

  createItem(video) {
    let self = this
    let url = `${App.api.root_url}/post_items`
    let data = {
      post_item: {
        post_id: this.data.get("postId"),
        remote_handle: video.url,
        description: video.title,
        file_name: video.file.name,
        mime_type: video.file.type,
      }
    }

    $.ajax({
      method: "POST",
      url: url,
      data: data,
      dataType: "json",
      beforeSend: function(xhr) {
        xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`);
      },
    }).done( (response, textStatus, jqXHR) => {
      self.appendVideo(response)
      self.resetModal()
    }).fail( (jqXHR, textStatus, errorThrown) => {
      console.log("Error")
    })
  }

  appendVideo(response) {
    let index = $(".post-item").length
    let item = response.data.attributes
    item["id"] = response.data.id
    let preview = "<i class='fas fa-video'></i>"
    let source   = document.getElementById("post-item-template").innerHTML
    let template = Handlebars.compile(source)
    let context  = { index: index, preview: preview, item: item }
    let html     = template(context)

    // Append item
    $("#post-items tbody").append(html)
  }

  showError() {
    $("#vimeo").append("<p class='text-danger'>There was an error</p>")
  }
}
