import { Controller } from "@hotwired/stimulus"
import * as Amplitude from 'amplitudejs'

export default class extends Controller {
  connect() {
    this.songs = JSON.parse(this.data.get("songs"))

    Amplitude.init({
      "songs": this.songs,
    })

    document.getElementById('song-played-progress').addEventListener('click', function( e ){
      var offset = this.getBoundingClientRect();
      var x = e.pageX - offset.left;
      Amplitude.setSongPlayedPercentage( ( parseFloat( x ) / parseFloat( this.offsetWidth) ) * 100 );
    })
  }
}
