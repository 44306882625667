export default class Model {
  static find(id) {
    let klass = eval(this.klass);
    let attrs = links.find(function(l){ return l.id == id});

    return new klass(attrs);
  }

  constructor(attrs = {}) {
    let self = this;

    self.klassName = self.constructor.name;
    this.constructor.attributes.forEach(function(attr){
      self[attr] = attrs[attr] || "";
    });
    self.model = self.constructor.model;
    self.aiguaData = self.toJSON();
  }

  isPersisted() {
    return this.id.length;
  }

  asJSON() {
    let output = {}
    let self = this;

    this.constructor.attributes.forEach(function(attr){
      output[attr] = self[attr];
    });
    output["model"] = self.constructor.model;

    return output;
  }

  toJSON() {
    return JSON.stringify(this.asJSON());
  }

  render() {
    // Always refresh object metadata before rendering
    this.aiguaData = this.toJSON();

    let source   = document.getElementById(this.constructor.templateId).innerHTML;
    let template = Handlebars.compile(source);
    let html    = template(this);
    return html;
  }

  // Override in derived class with an object that represents the attributes
  // to be sent to the server. Example:
  // { user: { email: this.email }, admin: false, first_name: this.name }
  params() {
   return {};
  }

  save() {
    let url    = this.collectionURL();
    let method = "POST";

    if ( this.isPersisted() ) {
      url    =  this.recordURL(this.id),
      method = "PUT";
    }

    return this._request({
      url: url,
      method: method,
      data: this.params(),
    });
  }

  delete() {
    let request = this._request({
      url: this.recordURL(this.id),
      method: "DELETE",
    });

    return request;
  }

  // URL helpers

  collectionURL() {
    return `${App.api.root_url}${this.constructor.endpoint}`;
  }

  recordURL(id) {
    return `${this.collectionURL()}/${id}`;
  }

  // Private

  _request(args = {}) {
    let url         = args.url;
    let method      = args.method;
    let data        = args.data || {};
    let processData = args.processData || true;
    let dataType    = "json";
    //let contentType = false;

    return $.ajax({
      url: url,
      method: method,
      data: data,
      dataType: dataType,
      //contentType: contentType,
      processData: processData,
      beforeSend: function(xhr) {
        let api_key = $('meta[name="user"]').attr('content');
        let csrf_token = $('meta[name="csrf-token"]').attr('content');

        xhr.setRequestHeader('X-CSRF-Token', csrf_token);
        xhr.setRequestHeader("Authorization", `Token token=${api_key}`);
      }
    });
  }
}