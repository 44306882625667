import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    lockScroll: { type: Boolean, default: true },
  };

  connect() {
    if (this.element.dataset.autoOpen === 'true') {
      this.open();
    }

    if (this.element.dataset.destroyOnClose === 'true') {
      $(this.element).on('hidden.bs.modal', () => this.element.remove());
    }
  }

  disconnect() {
    this.close();
  }

  open(event = null) {
    if (event) {
      event.preventDefault();
    }

    // Lock the scroll and save current scroll position
    if (this.lockScrollValue) {
      this.#lockScroll();
    }

    $(this.element).modal('show');
  }

  close() {
    // Unlock the scroll and restore previous scroll position
    this.#unlockScroll();

    $(this.element).modal('hide');
  }

  closeOnSuccess(event) {
    if (event.detail.success) {
      this.close();
    }
  }

  #lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

    if (this.restoreScrollValue) {
      // Save the scroll position
      this.#saveScrollPosition();

      // Add negative top position in order for body to stay in place
      document.body.style.top = `-${this.scrollPosition}px`;
    }
  }

  #unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

    // Restore the scroll position of the body before it got locked
    if (this.restoreScrollValue) {
      this.#restoreScrollPosition();

      // Remove the negative top inline style from body
      document.body.style.top = null;
    }
  }

  #saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  #restoreScrollPosition() {
    if (this.scrollPosition === undefined) return;

    document.documentElement.scrollTop = this.scrollPosition;
  }
}
