import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  delete(event) {
    let $target = $(this.element)
    let url = this.data.get("url")

    $target.fadeOut()

    $.ajax({
      url: url,
      method: 'DELETE',
      dataType: "json",
      contentType: false,
      processData: false,
      beforeSend: function(xhr) {
        xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`)
      },
      success: function(data) {
        $target.remove()
      },
      error: function(jqXHR, textStatus, errorThrown) {
        $target.fadeIn()
      }
    })
  }
}
