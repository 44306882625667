import { Controller } from "@hotwired/stimulus"
import { Sortable } from '@shopify/draggable'

export default class extends Controller {
  static targets = [ "container", "item" ]

  connect() {
    console.log("connected")
    let self = this
    const containerSelector = "[data-sortable-target='container']";
    const containers = document.querySelectorAll(containerSelector);
    const sortable = new Sortable(containers, {
      draggable: "[data-sortable-target~='item']",
      handle: "[data-sortable-target~='handle']",
    });

    sortable.on('sortable:stop', (event) => {
      let item = event.data.oldContainer.querySelector(".draggable--original")
      let data = {}

      // For backward compatibility
      if ( self.data.has("params") ) {
        let params = JSON.parse(self.data.get("params"))
        data[params.model] = params.params
        data[params.model]["position"] = event.newIndex + 1
      } else {
        data = {
          shop_image: {
            position: event.newIndex + 1
          }
        }
      }

      let url = item.dataset.url

      $.ajax({
        method: "PATCH",
        url: url,
        data: data,
        dataType: "json",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`);
        },
      })
    });
  }
}
