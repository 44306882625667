import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    this.open();
  }

  open() {
    this.element.classList.remove('d-none');

    setInterval(() => {
      this.close();
    }, 30000);
  }

  close() {
    this.element.remove();
  }
}
