import Link from "../models/link"

export default class LinkForm {
  constructor(link = new Link) {
    this.target = ".links-list";
    this.links = $(this.target);
    this.link = link;
    this.transient_id = this._generateTransientId();
    this.template = `
      <div class="overlay active" data-transient-id="${this.transient_id}">
        <div class="centered-element">
          <div class="card" style="width: 300px;">
            <div class="card-body">
              ${this._form()}
            </div>
          </div>
        </div>
        <button type="button" class="close">&times;</button>
      </div>
    `;
    this.el = $(this.template);
  }

  render() {
    $("body").append(this.el);
    this._attachEventListeners();
  }

  // Attached to a jQuery click event
  save(event) {
    event.preventDefault();
    // Get context from the event, because here `this` is a jQuery object
    self = event.data.context;

    let id    = self.el.find("[name='id']").val();
    let value = self.el.find("[name='additional_detail[value]']").val();
    let title = self.el.find("[name='additional_detail[title]']").val();

    let link = new Link({id: id, value: value, title: title});

    link.save()
      .done( (data) => {
        self.quit();
        link.id = data.id
        link.value = data.value

        if ( $(`#additional-detail-${id}`).length ) {
          $(`#additional-detail-${id}`).replaceWith(link.render());
        } else {
          $(`${self.target}`).append(link.render());
        }

        // Update data repo in the DOM
        links.push(link.asJSON());
      })
      .fail( (jqXHR, textStatus, errorThrown) => {
        var message = jqXHR.responseJSON.error;
        var error = new ApplicationError(message);
        error.render();
      });
  }

  quit(event) {
    // This method maybe called from a jQuery binding, or not.
    // Here we deal with this uncertainty.
    self = event == undefined ? this : event.data.context;

    $(".overlay").remove();
    $(".promoted").removeClass("promoted");
    self.el.remove();
  }

  _form() {
    let source   = document.getElementById("link-form-template").innerHTML;
    let template = Handlebars.compile(source);
    let context = this.link;
    let html    = template(context);
    return html;
  }

  _attachEventListeners() {
    // Set context to represent the current instance, because within the
    // function `this` will be the jQuery object that the event was binded to
    $(document).on("click", `[data-transient-id="${this.transient_id}"] .save`, { context: this }, this.save);
    $(document).on("click", `[data-transient-id="${this.transient_id}"] .cancel`, { context: this }, this.quit);
    $(document).on("click", `[data-transient-id="${this.transient_id}"] .close`, { context: this }, this.quit);
  }

  _generateTransientId() {
    let date = new Date().valueOf();
    return `link-${date}`;
  }
}

