import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { get, post } from '@rails/request.js'

const loadOptions = async (inputValue) => {
  if (!inputValue.length) return [];

  const response = await get(`${App.api.root_url}/tags`, {
    query: { q: inputValue },
    responseKind: 'json',
    headers: {
      Authorization: `Token token=${App.currentUser.api_key}`,
    }
  });

  if (response.ok) {
    const json = await response.json;

    return json.data.map((item) => {
      return { value: item.id, label: item.attributes.name };
    });
  } else {
    return [];
  }
}

const components = {
  DropdownIndicator: null,
};

const styles = {
  control: (styles, {isFocused}) => ({
    ...styles,
    boxShadow: isFocused ? '0 0 0 .2rem rgba(17,10,68,.25)' : 'none',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 10,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '80%',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    svg: {
      height: '12px',
      width: '12px',
    },
    color: '#000000',
    ':hover': {
      backgroundColor: 'rgba(17,10,68,.25)',
      color: '#2d1ab3',
    },
  }),
}

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: 'rgba(17,10,68,.25)',
    primary: 'rgba(17,10,68,.60)',
  },
})

const TaggingInput = (props) => {
  const { creatable = false, defaultValue, defaultOptions, maxItems, name, placeholder } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState(defaultValue);

  const handleCreate = async (inputValue) => {
    setIsLoading(true);

    const response = await post(`${App.api.root_url}/tags`, {
      body: JSON.stringify({ tag: { name: inputValue, origin: 'user' } }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token token=${App.currentUser.api_key}`,
      }
    });

    if (response.ok) {
      const json = await response.json;

      const newOption = { value: json.id, label: json.name };

      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue((value) => [...value, newOption]);
    } else {
      setIsLoading(false);
      console.error('Failed to create tag');
    }
  };

  const ReactSelectComponent = creatable ? AsyncCreatableSelect : AsyncSelect;

  return (
    <ReactSelectComponent
      className="react-select-tagging"
      classNamePrefix="react-select"
      components={components}
      formatCreateLabel={(inputValue) => `${window.I18n.t("actions.create")} "${inputValue}"`}
      loadingMessage={() => window.I18n.t("loading")}
      noOptionsMessage={({inputValue}) => creatable ? placeholder : `${window.I18n.t("search.filters.results_count", {count: 0})}`}
      isClearable={false}
      isDisabled={isLoading}
      isLoading={isLoading}
      isMulti
      loadOptions={loadOptions}
      name={name}
      onChange={(newValue) => setValue(newValue)}
      onCreateOption={handleCreate}
      openMenuOnClick={false}
      openMenuOnFocus={false}
      options={options}
      placeholder={placeholder}
      styles={styles}
      theme={theme}
      value={value}
      isOptionDisabled={() => maxItems && options.length >= maxItems}
    />
  );
}

export default TaggingInput;
