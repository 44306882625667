import Model from "../models/model"

export default class Link extends Model {
  static attributes = [ "id", "title", "value" ];
  static endpoint = "additional_details";
  static model = "additional_detail";
  static templateId = "link-template";

  static find(id) {
    this.klass = Link;
    return super.find(id);
  }

  params() {
    return {
      additional_detail: {
        title: this.title,
        value: this.value,
        locale: I18n.locale,
        kind: "link",
      }
    }
  }
}

