import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  uploadImage(event) {
    this.fileUploader({
      imageDim: [1350, 350],
      accept: 'image/*',
      maxFiles: 10,
      maxSize: this.MB(8),
      disableThumbnails: false,
      onFileUploadFinished: file => this.appendUploadedFile("image", file),
    });
  }

  uploadAudio(event) {
    this.fileUploader({
      accept: 'audio/*',
      maxFiles: 30,
      maxSize: this.MB(500),
      disableThumbnails: false,
      onFileUploadFinished: file => this.appendUploadedFile("audio", file),
    });
  }

  // Takes an number representing megabytes, and returns the equivalent bytes
  MB(how_many = 1) {
    return Number(how_many) * 1024 * 1024;
  }

  appendUploadedFile(post_type, file){
    let file_name = file.originalFile.name;
    let url = `${App.api.root_url}/post_items`
    let data = {
      post_item: {
        post_id: this.data.get("postId"),
        remote_handle: file.handle,
        description: file_name,
        file_name: file_name,
        mime_type: file.mimetype,
      }
    }

    $.ajax({
      method: "POST",
      url: url,
      data: data,
      dataType: "json",
      beforeSend: function(xhr) {
        xhr.setRequestHeader("Authorization", `Token token=${App.currentUser.api_key}`);
      },
    }).done( (response, textStatus, jqXHR) => {
      let index = $(".post-item").length
      let item = response.data.attributes
      item["id"] = response.data.id
      let preview = ""

      switch(post_type) {
        case "audio":
          preview = `
            <i class="fas fa-headphones"></i>
          `;
          break;
        case "image":
          let thumbnail = `https://cdn.filestackcontent.com/resize=w:100,h:100,f:crop/${item.remote_handle}`
          preview = `<img src='${thumbnail}' class='thumbnail' height="45" />`;
          break;
        default:
          preview = "No hem pogut incloure una vista prèvia de l'arxiu";
      }

      let source   = document.getElementById("post-item-template").innerHTML
      let template = Handlebars.compile(source)
      let context  = { index: index, preview: preview, item: item }
      let html     = template(context)

      // Append item
      $("#post-items tbody").append(html)
    }).fail( (jqXHR, textStatus, errorThrown) => {
      console.log("Error")
    })

  };

  fileUploader(pickerOptions = {}) {
    // Defaults for the uploader
    var pickerDefaults = {
      concurrency: 4,
      fromSources: ["local_file_system", "instagram", "facebook", "googledrive", "gmail", "webcam"],
      lang: $("html").attr("lang") || "ca",
      maxSize: this.MB(5),
      minFiles: 1,
      maxFiles: 1,
      imageMin: [200,200],
      customText: {},
    };

    // Build final params
    var pickerParams = _.defaults(pickerOptions, pickerDefaults);

    if (pickerParams.lang === "ca") {
      pickerParams.customText["Upload more"] = "Carregar més";
    }

    filestack_client.picker(pickerParams).open();
  }
}
